import React, { useState, useEffect } from "react";
import logo from "./images/logo.png";

const Nav = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);

  };

  useEffect(() => {
    const handleScroll = () => {
      if (isSidebarOpen === true) {
        // Prevent scrolling when the sidebar is open
        document.body.style.overflow = "hidden";
      } else {
        // Restore scrolling when the sidebar is closed
        document.body.style.overflow = "auto";
      }
    };

    handleScroll()
    
  }, [isSidebarOpen]);

  return (
    <div>
      <nav className={`nav-items ${isSidebarOpen ? "open" : ""}`}>
        <div className="mobile-menu-trigger" onClick={openSidebar}>
          <span className={isSidebarOpen ? "open" : ""}></span>
          <span className={isSidebarOpen ? "open" : ""}></span>
          <span className={isSidebarOpen ? "open" : ""}></span>
        </div>

        <div className="sidebar-content">
          <div className="logo">
            <img src={logo} alt="" />
            <div className="logo_text">Learn Africa</div>
          </div>

          <div className="nav-menu">
          {isSidebarOpen && (
          <div className="close-button" onClick={closeSidebar}>
           &times;
          </div>
        )}
          <div className="name">Learn Africa</div>

          <div className="nav-links">
            <div className="links1">
              <div className="link">Home</div>
              <div className="link">Library</div>
              <div className="link">Contact</div>
            </div>

            <div className="links2">
              <div className="link2">Login</div>
              <a className="join_button" href="https://app.learnafrica.ng">
                JOIN US
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
              >
                <g clipPath="url(#clip0_10423_12552)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5C0 4.81059 0.079009 4.62895 0.219646 4.49502C0.360282 4.36109 0.551026 4.28584 0.749916 4.28584H9.43845L6.21831 1.22068C6.07749 1.08658 5.99838 0.904705 5.99838 0.715059C5.99838 0.525414 6.07749 0.343536 6.21831 0.209436C6.35912 0.0753365 6.5501 0 6.74925 0C6.94839 0 7.13937 0.0753365 7.28019 0.209436L11.7797 4.49438C11.8495 4.56072 11.9049 4.63952 11.9427 4.72629C11.9805 4.81305 12 4.90606 12 5C12 5.09394 11.9805 5.18695 11.9427 5.27371C11.9049 5.36048 11.8495 5.43928 11.7797 5.50562L7.28019 9.79056C7.13937 9.92466 6.94839 10 6.74925 10C6.5501 10 6.35912 9.92466 6.21831 9.79056C6.07749 9.65646 5.99838 9.47459 5.99838 9.28494C5.99838 9.0953 6.07749 8.91342 6.21831 8.77932L9.43845 5.71416H0.749916C0.551026 5.71416 0.360282 5.63892 0.219646 5.50499C0.079009 5.37106 0 5.18941 0 5Z"
                    fill="#232F84"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10423_12552">
                    <rect width="12" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </a>
            </div>
          </div>
        </div>
        </div>

       
      </nav>
    </div>
  );
};

export default Nav;
