import Banner from "./Banner";
import Body from "./Body";
import Nav from "./Nav";

function App() {
  return (
    <div>
      <Nav />
      <Banner />
      <Body />
    </div>
  );
}

export default App;
