import React from "react";
import logo from './images/logo-bottom.png'

const Body = () => {
  return (

    <div className="container">
        <div className="card-container">
        <div className="cards">
      <div className="card">
        <div className="box1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clip-path="url(#clip0_10423_12571)">
              <path
                d="M1.71436 19.9999C1.71436 20.3155 1.97021 20.5714 2.28578 20.5714H29.7144C30.0299 20.5714 30.2858 20.3155 30.2858 19.9999V2.28564H1.71436V19.9999Z"
                fill="white"
              />
              <path
                d="M31.4286 0H0.571429C0.255859 0 0 0.255859 0 0.571429C0 0.886998 0.255859 1.14286 0.571429 1.14286H31.4286C31.7441 1.14286 32 0.886998 32 0.571429C32 0.255859 31.7441 0 31.4286 0Z"
                fill="white"
              />
              <path
                d="M20.5715 29.7144H16.5715V22.8572H17.1429C17.4585 22.8572 17.7144 22.6014 17.7144 22.2858V21.7144H14.2858V22.2858C14.2858 22.6014 14.5416 22.8572 14.8572 22.8572H15.4286V29.7144H11.4286C10.4819 29.7144 9.71436 30.4819 9.71436 31.4286V32.0001H22.2858V31.4286C22.2858 30.4819 21.5182 29.7144 20.5715 29.7144Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_10423_12571">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="card-header">Expert instruction</div>
        <div>
          {" "}
          <hr className="dash" />
        </div>
        <div className="card-text">
          The gradual accumulation of information about atomic and small-scale
          behaviour...
        </div>
      </div>
      <div className="card">
        <div className="box2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <g clip-path="url(#clip0_10423_12582)">
              <path
                d="M13.5947 12.4947C13.6323 12.465 13.6716 12.4384 13.7105 12.4104C13.8096 12.339 13.9131 12.2745 14.0207 12.2172C14.0629 12.1941 14.1038 12.1711 14.1472 12.1508C15.1515 11.663 16.3506 11.8274 17.1867 12.5671C17.2308 12.6056 17.2668 12.6492 17.3085 12.6898C17.3925 12.7708 17.4716 12.8567 17.5451 12.9473C17.5868 12.9994 17.6217 13.0557 17.6576 13.1108C17.7175 13.1976 17.7726 13.2876 17.8223 13.3806C17.8544 13.4413 17.8824 13.5031 17.9099 13.5662C17.951 13.6603 17.9867 13.7569 18.0172 13.8549C18.0375 13.9208 18.0567 13.9856 18.0735 14.0523C18.0965 14.1546 18.1138 14.2578 18.1253 14.3621C18.133 14.4272 18.1426 14.4914 18.1459 14.557C18.15 14.6715 18.147 14.7859 18.1371 14.9001C18.133 14.9561 18.1336 15.0124 18.1264 15.0686C18.1036 15.2328 18.0658 15.3947 18.0139 15.552C18.0139 15.5605 18.0112 15.5684 18.0084 15.5767L19.5177 16.4481L22.3282 11.5758L6.7539 2.58203L3.94336 7.45381L13.2417 12.8213C13.3496 12.7024 13.4679 12.5932 13.5947 12.4947Z"
                fill="white"
              />
              <path
                d="M1.1543 7.78827L5.65116 0L6.62414 0.561834L2.12728 8.3501L1.1543 7.78827Z"
                fill="white"
              />
              <path
                d="M26.4883 17.872L27.0507 16.8979L30.9448 19.1464L30.3824 20.1205L26.4883 17.872Z"
                fill="white"
              />
              <path
                d="M21.0586 16.0343L22.7449 13.1143L26.639 15.363L24.9527 18.283L21.0586 16.0343Z"
                fill="white"
              />
              <path
                d="M16.6702 15.9645C16.6444 15.917 16.6118 15.8736 16.5731 15.8358C16.6617 15.7438 16.7394 15.6417 16.8042 15.5317C17.2683 14.7247 16.9941 13.6947 16.1902 13.2253C16.0746 13.1605 15.9517 13.1098 15.8243 13.0735C15.7867 13.0623 15.748 13.0554 15.712 13.0469C15.6196 13.0269 15.5254 13.0145 15.431 13.0099C15.3904 13.0099 15.3506 13.0049 15.31 13.006C15.1757 13.009 15.0423 13.028 14.9125 13.0623C14.3397 13.213 13.8888 13.6551 13.7271 14.2252C13.5654 14.7953 13.7169 15.4082 14.1256 15.8374C14.0872 15.8747 14.0548 15.9176 14.029 15.9645L6.41229 31.1862C6.27369 31.4642 6.38649 31.8018 6.66425 31.9407C6.94201 32.0793 7.27988 31.9667 7.41849 31.6887L14.7849 16.9504V31.4376C14.7849 31.748 15.0366 31.9997 15.347 31.9997C15.6574 31.9997 15.9091 31.748 15.9091 31.4376V16.9548L23.2728 31.6887C23.4117 31.9667 23.7493 32.0793 24.0273 31.9407C24.3051 31.8018 24.4179 31.4642 24.279 31.1862L16.6702 15.9645Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_10423_12582">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="card-header">Training Courses</div>
        <div>
          {" "}
          <hr className="dash" />
        </div>
        <div className="card-text">
          The gradual accumulation of information about atomic and small-scale
          behaviour...
        </div>
      </div>
      <div className="card">
        <div className="box3">
          <svg
            style={{color: "white"}}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            width="32"
            height="32"
          >
            <path
              d="M623.1 136.9l-282.7-101.2c-13.73-4.91-28.7-4.91-42.43 0L16.05 136.9C6.438 140.4 0 149.6 0 160s6.438 19.65 16.05 23.09L76.07 204.6c-11.89 15.8-20.26 34.16-24.55 53.95C40.05 263.4 32 274.8 32 288c0 9.953 4.814 18.49 11.94 24.36l-24.83 149C17.48 471.1 25 480 34.89 480H93.11c9.887 0 17.41-8.879 15.78-18.63l-24.83-149C91.19 306.5 96 297.1 96 288c0-10.29-5.174-19.03-12.72-24.89c4.252-17.76 12.88-33.82 24.94-47.03l190.6 68.23c13.73 4.91 28.7 4.91 42.43 0l282.7-101.2C633.6 179.6 640 170.4 640 160S633.6 140.4 623.1 136.9zM351.1 314.4C341.7 318.1 330.9 320 320 320c-10.92 0-21.69-1.867-32-5.555L142.8 262.5L128 405.3C128 446.6 213.1 480 320 480c105.1 0 192-33.4 192-74.67l-14.78-142.9L351.1 314.4z"
              fill="white"
            ></path>
          </svg>
        </div>
        <div className="card-header">Training Courses</div>
        <div>
          {" "}
          <hr className="dash" />
        </div>
        <div className="card-text">
          The gradual accumulation of information about atomic and small-scale
          behaviour...
        </div>
      </div>
    </div>
        </div>

        <div className="logo-bottom"><img src={logo} alt="" /></div>
    </div>


   
  );
};

export default Body;
