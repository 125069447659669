import React from "react";
import bannerimg from "./images/banner.png";

const Banner = () => {
  return (
    <div className="banner">
      <div>
        <div className="bannertexts">
          <div className="allbannertexts">
            <div className="headersmall">For Better Future</div>
            <div className="headerlarge">
              Welcome to <br />
              LearnAfrica Online
            </div>

            <div className="access">
              Access thousands of school textbooks and learning materials
              online.
            </div>

            <div>
              <button className="banner-button">Learn More</button>
            </div>
          </div>
        </div>
      </div>
      <div className="bannerimage">
        <img src={bannerimg} alt="" className="img" />
      </div>
    </div>
  );
};

export default Banner;
